import axios from "axios"
import { getAll } from "../../../endpoints/getAll"

const URL = getAll.devoluciones_notas_credito

export const updateDevolucionNC = async (id, data) => {
  try {
    if (!id) throw new Error('Devolucion - NC: Missing id')

    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}