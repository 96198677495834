import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { successResponse } from "../../../helpers/successResponse"

const URL = getAll.devoluciones_notas_credito

export const createDevolucionNC = async (data) => {
  try {
    const response = await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}

/* <Table style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <tbody>
          {gevList?.length
            ? gevList.map(({ ACCT_CODE, BILL_NAME_TEXT, BILL_ADDR_TEXT, ENTRY_CODE, ENTRY_DATE, TOTAL_AMT, GUIA_NUM }) => (
              <tr key={`gev-bitacora-registros-data-${GUIA_NUM}-${ENTRY_DATE}`}>
                <td>
                  <div className="d-flex justify-content-between flex-wrap">
                    <p>ACCT_CODE: <b>{ACCT_CODE ?? 'Error'}</b></p>
                    <p>BILL_NAME_TEXT: <b>{BILL_NAME_TEXT ?? 'Error'}</b></p>
                    <p>BILL_ADDR_TEXT: <b>{BILL_ADDR_TEXT ?? 'Error'}</b></p>
                    <p>ENTRY_CODE: <b>{ENTRY_CODE ?? 'Error'}</b></p>
                    <p>TOTAL_AMT: <b>{TOTAL_AMT ?? 'Error'}</b></p>
                  </div>
                </td>
              </tr>
            ))
            : <tr><td className="text-center fw-bolder fs-5 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table> */