import axios from "axios"
import { getAll } from "../../endpoints/getAll"
import { successResponse } from "../../helpers/successResponse"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"

const URL = getAll.metodos_entrega

export const getAllMetodosEntrega = async (limit = 9999, params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}