import { useEffect, useState } from "react"


// Redux
import { useDispatch, useSelector } from "react-redux"
import { resultadosDevoluciones } from "../../../redux/actions/devoluciones"
import { fireToaster } from "../../../redux/actions/toaster"


// Components
import CustomLoader from "../../CustomLoader"
import Modal from "../../Modal"
import Table from "../../Table"
import FormInput from "../../FormInput"
import Button from "../../Button"


// Services
import { getAllDevoluciones } from "../../../services/devoluciones/getAll.service"
import { getAllGEV } from "../../../services/gev/getAll.service"
import { updateDevolucion } from "../../../services/devoluciones/update.service"


// Helpers
import { toasterErrorFormatter } from "../../../helpers/toasterErrorFormatter"
import { responseToasterContent } from "../../../helpers/toasterContent"
import { filtrosFormatter } from "../../AccordionFilter/Devoluciones/Devoluciones"


const CONSTANT = {
  reduxStateSelector: 'devoluciones',
  reduxSetResults: resultadosDevoluciones,

  form: {
    id: null,
    gev: ''
  }
}

export const ModalBitacoraGEV = ({ data, isOpen, handleClose }) => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [gevList, setGevList] = useState([])
  const [form, setForm] = useState(CONSTANT.form)
  const [editGEV, setEditGEV] = useState(false)
  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    if (!data && !data?.gev?.length) return

    setForm(prev => ({
      ...prev,
      gev: data?.gev ?? '',
      id: data?.id
    }))

    if (data?.gev?.length) {
      const gevsForm = { gevs: [data?.gev] }

      setIsConsulting(true)

      getAllGEV(gevsForm)
        .then(({ data }) => {
          setGevList(data)
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }
    else {
      setEditGEV(true)
    }

    return () => {
      setEditGEV(false)
      setForm(CONSTANT.form)
    }

  }, [data])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleEditGEV = () => {
    setEditGEV(prev => !prev)
  }


  const updateGEV = async () => {
    const formData = {
      gev: Number(form?.gev)
    }

    setIsConsulting(true)

    await updateDevolucion(form?.id, formData)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))
        await fetchDevoluciones()
        handleClose()
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
        setEditGEV(false)
      })
  }


  const fetchDevoluciones = async () => {
    await getAllDevoluciones(filtrosFormatter(filtros), showing_results)
    .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  return (
    <Modal
      display={isOpen}
      handleValue={handleClose}
    >
      {isConsulting && <CustomLoader blocking='partial' />}

      <h4>GEV</h4>

      <section className="d-flex gap-1">
        <FormInput
          name='gev'
          type='number'
          value={form?.gev ?? ''}
          handleValue={handleInputChange}
          size='col'
          sizeDesk='col-md-6'
          isDisabled={!editGEV}
        />

        {editGEV
          ? <div className="d-flex gap-50 h-100 mt-50">
            <Button
              className={'p-50'}
              color={'success'}
              icon={'Check'}
              onClick={() => updateGEV()}
            />

            <Button
              className={'p-50'}
              color={'danger'}
              icon={'X'}
              onClick={handleEditGEV}
            />
          </div>

          : <div className="d-flex gap-50 h-100 mt-50">
            <Button className={'p-50'} icon={'Settings'} onClick={handleEditGEV} />
          </div>
        }
      </section>

      <hr className="my-50" />

      <h5>Tabla de registros</h5>

      <section>
        <Table style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <tbody>
            {gevList?.length
              ? gevList.map(({ ACCT_CODE, BILL_NAME_TEXT, BILL_ADDR_TEXT, ENTRY_CODE, ENTRY_DATE, TOTAL_AMT, GUIA_NUM }) => (
                <tr key={`gev-bitacora-registros-data-${GUIA_NUM}-${ENTRY_DATE}`}>
                  <td>
                    <div className="d-flex justify-content-between flex-wrap">
                      <p>ACCT_CODE: <b>{ACCT_CODE ?? 'Error'}</b></p>
                      <p>BILL_NAME_TEXT: <b>{BILL_NAME_TEXT ?? 'Error'}</b></p>
                      <p>BILL_ADDR_TEXT: <b>{BILL_ADDR_TEXT ?? 'Error'}</b></p>
                      <p>ENTRY_CODE: <b>{ENTRY_CODE ?? 'Error'}</b></p>
                      <p>TOTAL_AMT: <b>{TOTAL_AMT ?? 'Error'}</b></p>
                    </div>
                  </td>
                </tr>
              ))
              : <tr><td className="text-center fw-bolder fs-5 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </section>
    </Modal >
  )
}