import { useEffect, useState } from "react"


// Redux
import { useDispatch, useSelector } from "react-redux"
import { fireToaster } from "../../../redux/actions/toaster"
import { resultadosDevoluciones } from "../../../redux/actions/devoluciones"


// Components
import { filtrosFormatter } from "../../AccordionFilter/Devoluciones/Devoluciones"
import CustomLoader from "../../CustomLoader"
import Table from "../../Table"
import Button from "../../Button"
import FormInput from "../../FormInput"
import Modal from "../../Modal"


// Services
import { getAllDevoluciones } from "../../../services/devoluciones/getAll.service"
import { createDevolucionNC } from "../../../services/devoluciones/notasCredito/create.service"
import { deleteDevolucionNC } from "../../../services/devoluciones/notasCredito/delete.service"
import { updateDevolucionNC } from "../../../services/devoluciones/notasCredito/update.service"


// Helpers
import { responseToasterContent } from "../../../helpers/toasterContent"
import { toasterErrorFormatter } from "../../../helpers/toasterErrorFormatter"


const CONSTANT = {
  reduxStateSelector: 'devoluciones',
  reduxSetResults: resultadosDevoluciones,

  form: {
    nota_credito: '', //int
    devolucion_id: null, // int
  }
}

export const ModalBitacoraNC = ({ data, isOpen, handleClose }) => {
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()
  const [isConsulting, setIsConsulting] = useState(false)
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [tab, setTab] = useState('form')
  const [editNC, setEditNC] = useState({ ...CONSTANT.form })


  useEffect(() => {
    if (!data) return

    setForm(prev => ({
      ...prev,
      devolucion_id: data?.devolucion_id,
    }))

    return () => {
      setForm(CONSTANT.form)
      setTab('form')
      setResetEditNC()
    }
  }, [data])


  const handleFullClose = () => {
    setResetEditNC()
    setForm(CONSTANT.form)
    setTab('form')
    handleClose()
  }


  const setResetEditNC = () => { handleEditNC(CONSTANT.form) }


  const handleTab = (e) => {
    const { name } = e.target
    setTab(name)
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleEditNCInputChange = (e) => {
    const { name, value } = e.target

    setEditNC(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleFetch = async (e) => {
    e.preventDefault()

    const formatData = {
      nota_credito: Number(form?.nota_credito),
      devolucion_id: Number(form.devolucion_id),
    }

    setIsConsulting(true)

    await createDevolucionNC(formatData)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))
        await fetchDevoluciones()
        handleClose()
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  const fetchDevoluciones = async () => {
    await getAllDevoluciones(filtrosFormatter(filtros), showing_results)
    .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  const deleteNC = async (id) => {
    await deleteDevolucionNC(id)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))
        await fetchDevoluciones()
        handleClose()
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  const handleEditNC = (data) => {
    setEditNC(() => ({
      nota_credito: data?.nota_credito,
      id: data?.id
    }))
  }


  const updateNC = async () => {
    const formData = {
      devolucion_id: data?.devolucion_id,
      nota_credito: editNC?.nota_credito
    }

    await updateDevolucionNC(editNC?.id, formData)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))
        await fetchDevoluciones()
        handleClose()
        setResetEditNC()
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  return (
    <Modal
      display={isOpen}
      handleValue={handleFullClose}
    >
      {isConsulting && <CustomLoader blocking={'partial'} />}

      <div className="d-flex justify-content-between">
        <h4 className="my-0">Devolución #{data?.devolucion_id}</h4>

        <nav className="bitacora-devoluciones-tabs">
          [
          <button onClick={handleTab} name="form">Formulario</button>
          <span>|</span>
          <button onClick={handleTab} name="logs">Registros</button>
          ]
        </nav>
      </div>

      <hr className="mt-1" />

      {tab === 'form' &&
        <section>
          <h5 className="text-center mb-1">Notas de crédito | Formulario</h5>

          <form className="d-flex flex-column h-max">
            <div className="row">
              <FormInput
                labelText='Nota de crédito'
                placeholder='Nota de crédito'
                type={'number'}
                name='nota_credito'
                value={form?.nota_credito ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>
          </form>

          <div className="d-flex justify-content-between mt-2">
            <Button
              type="submit"
              text={'Cancelar'}
              color="danger"
              className={'w-fit'}
              icon={'X'}
              onClick={handleFullClose}
            />

            <Button
              type="submit"
              text={'Agregar'}
              color="success"
              className={'w-fit'}
              icon={'Plus'}
              onClick={(e) => handleFetch(e)}
            />
          </div>
        </section>
      }

      {tab === 'logs' &&
        <section>
          <h5 className="text-center mb-1">Notas de crédito | Tabla de registros</h5>

          <Table style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <tbody>
              {data?.nc?.length
                ? data?.nc.map(({ nota_credito, id, created_at }) => (
                  <tr key={`devolucion-bitacora-registros-data-${id}-${created_at}`}>
                    <td className="col">
                      <div className="d-flex justify-content-between fs-4">
                        <p className="mb-0">ID: <b>{id ?? 'Error'}</b></p>
                      </div>
                    </td>

                    <td>
                      <div className="col w-100 d-flex gap-50 w-fit fs-4 align-items-center justify-content-end">NC:
                        {editNC?.id === id
                          ? <FormInput
                            placeholder='Nota de crédito'
                            type={'number'}
                            name='nota_credito'
                            value={editNC?.nota_credito ?? ''}
                            handleValue={handleEditNCInputChange}
                            size='col-3'
                            sizeDesk='col-md-3'
                            margin={'mb-0'}
                          />

                          : <b>{nota_credito ?? 'Error'}</b>
                        }
                      </div>
                    </td>

                    <td className="col-1">
                      {editNC?.id === id
                        ? <div className="d-flex gap-50 h-100">
                          <Button className={'p-50'} color={'success'} icon={'Check'} onClick={() => updateNC()} />

                          <Button className={'p-50'} color={'danger'} icon={'X'} onClick={setResetEditNC} />
                        </div>

                        : <div className="d-flex gap-50 h-100">
                          <Button className={'p-50'} icon={'Settings'} onClick={() => handleEditNC({ nota_credito, id })} />

                          <Button className={'p-50'} color={'danger'} icon={'X'} onClick={() => deleteNC(id)} />
                        </div>
                      }
                    </td>
                  </tr>
                ))
                : <tr><td className="text-center fw-bolder fs-5 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
              }
            </tbody>
          </Table>
        </section>
      }
    </Modal >
  )
}