import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../../views/Logistica/Listado";


export const LogisticaRouter = () => {
  return (
    <Routes>
      <Route path='/reportes/*' element={<Listado />} />

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
}
